import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../slices/commonSlice";
import Loading from "../include/Loading";
import { useNavigate } from "react-router-dom";

const Notifications = () => {

   const dispatch = useDispatch();
   const navigate = useNavigate();

      useEffect(() => {
        dispatch(getNotifications());
      }, []);

      const notificationDetails = useSelector((state)=>state.common.notificationDetails);
      const isLoading = useSelector((state) => state.common.isLoading);

      const onClickAction = (e, item) => {
         e.preventDefault();
         window.closePopup();
         if(item.action_type=='user'){
            navigate("/profile/"+item.from_user_slug);
         } else if(item.action_type=='url' && item.url!=''){
            //  window.location = item.url;
            navigate("/profile/"+item.from_user_slug);
         }
     }
  
  return (
    <div className="comman-model comman-side-popup notification-model" id="Notifications">
      <Loading loading={isLoading} />
    <div className="comman-popup-inner">
       <div className="top-model-header">
          {/* <a href="javascript:void(0);" className="backarrow open-popup" data-popup-id="LogIn"><i><img src="../img/backarrow.svg" alt=""/></i></a> */}
          <div className="pop-top-title">
             <h2>Notifications</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel"><i><img src="../img/close.png" alt=""/></i></a>
       </div>
       <div className="popup-model-body">
         <ul className="notifyfollower">
         {notificationDetails && notificationDetails.map((item, index) => (  
          <li key={index} onClick={(e)=>onClickAction(e, item)} style={{cursor:'pointer'}}>
             <div className="notificationrow"> 
                   <div className="notifyimg">
                      <img src="../img/notifyimg.png" alt="" /> 
                   </div>
                   <div className="notifycontent">
                      <h2 className="notifytitle">  <b>{item.title} </b> {item.message}  <span>{item.created_at}</span></h2>
                      
                   </div> 
             </div>
          </li>
           ))}
         </ul>
       </div>
    </div>
    <div className="shadowpt clearfix"></div>
 </div>
  );
};

export default Notifications;
