import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { suggestSwopAction } from "../../slices/productDetailSlice";
import Swal from 'sweetalert2';
const Swopsuggestion = ({suggestProducts,postedproductid}) => {
   const dispatch = useDispatch();

  //  console.log('qq2223388',suggestProducts);

const [productlist, setProductList] = useState([]);
const [suggestModalProductId, setsuggestModalProductId] = useState(0);
const [posted_product_id, setPostedProductId] = useState(postedproductid);
const [result, setResult] = useState(null);
const [suggestModalOpened, setsuggestModalOpened] = useState(false);

useEffect(() => {
   if (Array.isArray(suggestProducts)) {
       setProductList(suggestProducts);
       setPostedProductId(postedproductid);
   }
   
}, [suggestProducts,postedproductid]);


const suggestSwop = async (e, suggest = 1, product_details = {}) => {
   try {


   if (suggest && !suggestModalProductId) {
   
     Swal.fire({
       title: "Alert",
       text: "Please select an item to swop.",
       icon: "error",
     });
     return;
   }

     const result = await dispatch(suggestSwopAction({
       to_posted_product_id: posted_product_id,
       from_posted_product_id: suggest ? suggestModalProductId : "",
     }));
     
     console.log('API Response:', result);
     setResult(result);
     // Check if the API call was successful
     if (result.payload && result.payload.status === 200) { 
          // Show success message
         Swal.fire("Success!", result.payload.message, "success");
     } else {
       Swal.fire("Alert!", result.payload.message, "error");
      
     }
   } catch (error) {
     // Handle any errors
     console.log('ddddddddd:', error);
     alert('An error occurred while adding item to wishlist.');
   }

  
 };

 useEffect(() => {
   if (result && result.payload && result.payload.status === 200) {
       console.log('Result is:', result); // Check the value of result
       var myElement = document.getElementById('chooseanyone');
       console.log('Element:', myElement); // Check if the element exists
       if (myElement && myElement.classList.contains('open-it')) {
           console.log('Removing class'); // Debugging message
           // Remove the class
           myElement.classList.remove('open-it');
           document.body.classList.remove('hidden-scroll');
       }
   }
}, [result]);


  
  return (
    <div className="comman-model comman-side-popup choose-any-model" id="chooseanyone">
    <div className="comman-popup-inner">
       <div className="top-model-header">
          <div className="pop-top-title">
             <h2>Choose Any one</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel"><i><img src="../img/close.png" alt=""/></i></a>
       </div>
       <div className="popup-model-body"> 
<ul className="choose-any-cards">
{productlist.map((item, index) => {
                    return (
<li key={index} onClick={(e) =>
                          setsuggestModalProductId(
                            item.posted_product_id,
                          )
                        }><div className="sa-list-box" >  

    <div className="sa-list-box__image" >
       <img src={item.image} alt="Swop" />
       
    </div>
    <div className="sa-list-box__content">
       <div className="sa-list-box__content__title">{item.title}</div>
    </div> 
</div></li>
                    )}
)}
</ul>
           
       </div>
       <div className="model-footer-panel"> <button className="btn btn-primary" onClick={(e) => suggestSwop(e, 1)}>Suggest this swop</button></div>
    </div>
    <div className="shadowpt clearfix"></div>
 </div>
  );
};

export default Swopsuggestion;
