import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVehicalDetail,addwishlistItem,addReport,addSellerInquiry } from "../../slices/productDetailSlice";
import Loading from "../include/Loading";
import Cards from "../cards/Cards";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utils/config";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignIn/SignUp";
import Slider from "react-slick";


import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const VehicleDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
 

  const [isOpen, setIsOpen] = useState(false);
  const [selected_report_reason, setSelectedreportreason] = useState('');
  const [selected_report_reason_other, setSelectedreportreasonother] = useState('');
  const [posted_product_id,setPostedproductid] = useState('');
  const [product_user_id,setProductuserid] = useState('');
  const [result, setResult] = useState(null);

 
  const [shareitem, setShareitem] = useState({
      share_title: "",
      share_description: "",
      share_url: window.location.href,
      share_hashtag: "#SwopAnything",
  });
  const [wishlistitem, setWishlistitem] = useState({
    is_wishlisted: 0,
   
});
const [reportitem, setReportitem] = useState({
   is_reported: 0,
  
});
const [report_reasons, setReportreasons] = useState([]);
const [mobileNumbers, setMobileNumber] = useState('');
const [whatsappnumber, setWhatsAppNumber] = useState('');
  useEffect(() => {
    dispatch(getVehicalDetail(slug)); // Dispatch your API call action here using slug
  }, [dispatch, slug]);

  const details = useSelector((state) => state.productDetail.vehicals);
  
  const filteredImages = details && details.image ? details.image.filter(image => 
    image.image_type !== 'insurance' && 
    image.image_type !== 'noc' && 
    image.image_type !== 'rc'
  ) : [];

  // Sort the filtered images based on order_sort
filteredImages.sort((a, b) => a.order_sort - b.order_sort);


useEffect(() => {
   window.scrollTo({ top: 0, behavior: "smooth" });
 }, []);


  useEffect(() => {
    if(details && details.product_details){
    
      setShareitem({share_title:details.product_details.name, share_description:details.product_details.description, share_url:details.product_details.deep_url});
      setWishlistitem({is_wishlisted:details.is_wishlisted});
      setReportitem({is_reported:details.is_reported});
      setReportreasons(details.report_reasons);
      setPostedproductid(details.product_details.id);
      setProductuserid(details.user.id);
      setWhatsAppNumber(details.user.mobile_prefix_code+details.user.mobileno);
    }
  }, [details]);

  const viewProfile = (e, user_slug) => {
    e.preventDefault();
    navigate("/profile/" + user_slug);
  };

  const addDefaultSrc = (ev) => {ev.target.src = "../../img/profile-img.png";}

  const openShareModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const closeShareModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

 


  const wishlist = async (item) => {
    try {
      let action = !wishlistitem.is_wishlisted;
      const result = await dispatch(addwishlistItem({
        productId: item.id,
        action: action ? 1 : 0,
        type:'Vehicle'
      }));
      setResult(result);
      // console.log('API Response:', result);
  
      // Check if the API call was successful
      if (result.payload.status === 200) {
        // Show success message
        setWishlistitem({is_wishlisted:!wishlistitem.is_wishlisted});
       // window.location.reload();
      } else {
      
      }
    } catch (error) {
      // Handle any errors
      // console.error('Error adding item to wishlist:', error);
      alert('An error occurred while adding item to wishlist.');
    }
  };


  
 
  const isLoading = useSelector((state) => state.productDetail.isLoading);

  const openReportModal = () => {
    //e.preventDefault();
    if(reportitem.is_reported == 1){
      Swal.fire("Alert!", 'This product is already reported by you!', "Report");
      document.getElementById('RequestCallback').classList.remove('open-it');
    } else {
    setSelectedreportreason('');
    setSelectedreportreasonother('');
    }

     
  };
 
  const handleInputChange = (event) => {
   let inputMobileNumber = event.target.value;
   // Remove any non-numeric characters
   inputMobileNumber = inputMobileNumber.replace(/\D/g, '');
   // Limit input to 15 characters
   if (inputMobileNumber.length <= 15) {
       inputMobileNumber = inputMobileNumber.slice(0, 15);
   }
   setMobileNumber(inputMobileNumber);
};



// Construct the WhatsApp link
const whatsappLink = "https://wa.me/" + whatsappnumber;



  

  const sellerinquiry = async (type) => {
   // Getting the input values
   let name = '';
   let mobileNumber = '';
   if (type == 'login') {
    
     const signInResponseString = localStorage.getItem("signInResponse");
     const signInResponse = JSON.parse(signInResponseString);
     name = signInResponse && signInResponse.first_name ? signInResponse.first_name + signInResponse.last_name : '';
     mobileNumber = signInResponse && signInResponse.mobileno ? signInResponse.mobileno : '';
     setMobileNumber(mobileNumber);
   } else {
     
     name = document.querySelector('input[name="name"]').value;
     mobileNumber =mobileNumbers;
   }
   var nameInput = document.getElementById("nameInput");
   // Check if name is empty
   if (!name.trim()) {
      nameInput.classList.add("error");
     
     return false;
   } else {
      nameInput.classList.remove("error");

   }
 
   // Check if mobile number is empty or not a number
   if (!mobileNumber.trim() || isNaN(mobileNumber)) {
     Swal.fire({
       title: "Alert",
       text:"Please enter a valid mobile number with 15 digits.",
       icon: "error",
     });
     return false;
   } 
  
   try {
     const result = await dispatch(
       addSellerInquiry({
         product_id: posted_product_id,
         product_user_id: product_user_id,
         name: name, // Adding name to the payload
         mobile_number: mobileNumber, // Adding mobile number to the payload
       })
     );
   //   console.log('API Response:', result);
 
     // Check if the API call was successful
     if (result.payload && result.payload.status === 200) {
       // Show success message
       Swal.fire("Success!", result.payload.message, "success");
       
       document.getElementById('RequestCallback').classList.remove('open-it');
       document.body.classList.remove('hidden-scroll');
     } else {
       Swal.fire("Alert!", result.payload.message, "error");
     }
   } catch (error) {
     // Handle any errors
   //   console.log('Error adding item to wishlist:', error);
     alert('An error occurred while adding item to wishlist.');
   }
 };
 
 const closeRequestpopup =async () =>{
   const nameInput = document.querySelector('input[name="name"]');
   const mobileNumberInput = document.querySelector('input[name="mobile_number"]');
   
   // Check if the elements exist before setting their values
   if (nameInput && mobileNumbers) {
     // Set the input fields to blank
     nameInput.value = '';
     mobileNumberInput.value = '';
     setMobileNumber('');
   }
   
   document.getElementById('RequestCallback').classList.remove('open-it');
   document.body.classList.remove('hidden-scroll');
 }
 

  const reportSwop = async (e) => {
    if (selected_report_reason == "") {
      Swal.fire({
        title: "Alert",
        text: "Please select a reason.",
        icon: "error",
      });
     return false;
    }

    if (
      selected_report_reason == "Other" &&
      selected_report_reason_other == ""
    ) {
      Swal.fire({
        title: "Alert",
        text: "Please enter other reason",
        icon: "error",
      });
     return false;
    }

  
    try {
      
      const result = await dispatch(addReport({
        reason:
        selected_report_reason == "Other"
          ? selected_report_reason_other
          : selected_report_reason,
      posted_product_id: posted_product_id,
      type: 'Vehicle',
      }));
      setResult(result);
      // console.log('API Response:', result);
  
      // Check if the API call was successful
      if (result.payload && result.payload.status === 200) { 
           // Show success message
          Swal.fire("Success!", result.payload.message, "success");
          setReportitem({is_reported:1});
          document.getElementById('chooseanyonereport').classList.remove('open-it');
          document.body.classList.remove('hidden-scroll');
      } else {
        Swal.fire("Alert!", result.payload.message, "error");
       
      }
    } catch (error) {
      // Handle any errors
      // console.log('Error adding item to wishlist:', error);
      alert('An error occurred while adding item to wishlist.');
    }
  };

  useEffect(() => {
    if (result && result.payload && result.payload.status === 200) {
     
      dispatch(getVehicalDetail(slug));
      setSelectedreportreason('');
      setSelectedreportreasonother('');

   // Accessing DOM elements safely
   const nameInput = document.querySelector('input[name="name"]');
   const mobileNumberInput = document.querySelector('input[name="mobile_number"]');
   
   // Check if the elements exist before setting their values
   if (nameInput && mobileNumbers) {
     // Set the input fields to blank
     nameInput.value = '';
     mobileNumberInput.value = '';
     setMobileNumber('');
   }
}
  }, [result]);

 // Custom arrow component for the previous button
const PrevArrow = ({ className, onClick }) => (
   <button type="button" className="prevarrow" onClick={onClick}><i><img src="../../img/toparrow.svg" alt=""/></i></button>
 );
 
 // Custom arrow component for the next button
 const NextArrow = ({ className, onClick }) => (
   <button type="button" className="nextarrow" onClick={onClick}><i> <img src="../../img/bottomarrow.svg" alt=""/> </i></button>
 );


  const [navSlider, setNavSlider] = useState(null); // State to hold reference to navigation slider
  const [mainSlideIndex, setMainSlideIndex] = useState(0);
  const [mainSlider, setMainSlider] = useState(null); // State to hold reference to main slider

  const slickSliderPrevSettings = {
    autoplay: false,
    vertical: true,
    infinite: false,
    slidesPerRow: 1,
    slidesToShow: 1,
    arrows: false,
    draggable: false,
    asNavFor: navSlider, // Connect to the navigation slider
    afterChange: setMainSlideIndex, // Update state on slide change
  };

  const slickSliderThumbSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    focusOnSelect: true,
    asNavFor: mainSlider, // Connect to the main slider
    ref: setNavSlider,
    prevArrow: <PrevArrow className="prevarrow" />,
    nextArrow: <NextArrow className="nextarrow" />,
  };

  const handleImageClick = (image) => {
   setCurrentImage(image);
   setIsModalOpen(true);
 };
 
 const closeModal = () => {
   setIsModalOpen(false);
 };

 
  return (
    <main className="sa-main-wrap sa-trems-pages-wrap">
       <div className="container">
               <div className="breadcrumpart">
                  <ul>
                     <li>
                        <a href="/">Home</a>
                     </li>
                     <li>
                        Details
                     </li>
                  </ul>
               </div>
               <section className="details-wrap">
                  <div className="row detailsrow">
                     <div className="col-lg-7">
                        <div className="module-gallery unsticky">
                           <div className="slider-wrapper">
                              
                              <div className="sldnavside">
                               {/* <button type="button" className="prevarrow"><i><img src="../../img/toparrow.svg" alt=""/></i></button> */}
                                  <ul className="slider-thumb">
                                          <Slider {...slickSliderThumbSettings} >
                                             {filteredImages.map(image => {
                                                return (
                                                   <li className="type-image" key={image}>
                                                   <img src={API_BASE_URL + image.small_url} alt="" />
                                                   </li>
                                                );
                                             })}
                                          </Slider>
                                    </ul> 
                                 {/* <button type="button" className="nextarrow"><i> <img src="../../img/bottomarrow.svg" alt=""/> </i></button> */}
                              </div>

                               <div className="sldprevpt">
                                 <ul className="slider-preview">

                                    <Slider {...slickSliderPrevSettings} ref={setMainSlider}>
                                          {filteredImages.map(slide => {
                                             return (
                                                <li key={slide} className="type-image" onClick={() => handleImageClick(API_BASE_URL + slide.url)}>
                                                <img src={API_BASE_URL + slide.url} alt="" />
                                                </li>
                                             );
                                          })}
                                          </Slider>  
                                 </ul>
                              </div> 

   

                           </div>
                        </div>
                        <div className="main-overview-outer">
                           <div className="outer-card-container">
                              <h2>Car Overview</h2>
                              <ul className="row card-details-list">
                                 <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/registration.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Color </div>
                                          <span className="value-text ">{details &&
                            details.product_details &&
                            details.product_details.color}</span>
                                       </div>
                                    </div>
                                 </li>
                                 {/* <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/insurance.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Insurance Validity </div>
                                          <span className="value-text ">Comprehensive</span>
                                       </div>
                                    </div>
                                 </li> */}
                                 <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/fuel-type.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Fuel Type </div>
                                          <span className="value-text ">{details &&
                            details.product_details &&
                            details.product_details.fuel_type}</span>
                                       </div>
                                    </div>
                                 </li>
                                 {/* <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/seats.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Seats </div>
                                          <span className="value-text ">5 Seats</span>
                                       </div>
                                    </div>
                                 </li> */}
                                 <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/kms-driven.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Kms Driven </div>
                                          <span className="value-text ">{details &&
                            details.product_details &&
                            details.product_details.kms_driven}  Kms</span>
                                       </div>
                                    </div>
                                 </li>
                                 {/* <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/rto.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">RTO </div>
                                          <span className="value-text ">RJ45</span>
                                       </div>
                                    </div>
                                 </li> */}
                                 <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/import.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Type </div>
                                          <span className="value-text ">{details &&
                            details.product_details &&
                            details.product_details.tractor_ownership}</span>
                                       </div>
                                    </div>
                                 </li>
                                 {/* <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="img/engine.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Engine Displacement </div>
                                          <span className="value-text ">{details &&
                            details.product_details &&
                            details.product_details.tractor_ownership} cc</span>
                                       </div>
                                    </div>
                                 </li> */}
                                 <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/transmission.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Transmission </div>
                                          <span className="value-text ">{details &&
                            details.product_details &&
                            details.product_details.transmission_type}</span>
                                       </div>
                                    </div>
                                 </li>
                                 <li className="col-md-6">
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/year-1.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="label ">Year of Manufacture </div>
                                          <span className="value-text ">{details &&
                            details.product_details &&
                            details.product_details.year}</span>
                                       </div>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                           <div className="outer-card-container">
                              <h2>Description/Comments</h2>
                              <ul className="row card-details-list">
                                 <li className="col-md-12">
                                    <p> {details &&
                        details.product_details &&
                        details.product_details.description}</p>
                                 </li>
                              </ul>
                           </div>
                           {details &&
                     details.condition &&
                     details.condition.length > 0 &&   
                           <div className="outer-card-container">
                              <h2>Vehicle Conditions</h2>
                              <ul className="row card-details-list">
                              {details &&
                     details.condition &&
                     details.condition.length > 0 &&
                     details.condition.map((item, index) => (
                     <React.Fragment key={`${item.attr_id}-${index}`}>
                    {item.excellent === 1 && (
                  
                     <li className="col-md-6" key={index}>
                     <div className="card-each-list">
                        <i className="icon-box">
                        <img src="../../img/tickgreen.svg" alt=""/>
                        </i>
                        <div className="label-text">
                           <div className="value-text label-left">{item.attr_id} : <span className="tagspt green">Excellent</span></div>
                        </div>
                     </div>
                   </li>
                    )}
                    {item.good === 1 && (
                         <li className="col-md-6" key={index}>
                         <div className="card-each-list">
                            <i className="icon-box">
                            <img src="../../img/tickgreen.svg" alt=""/>
                            </i>
                            <div className="label-text">
                               <div className="value-text label-left">{item.attr_id} : <span className="tagspt yellow">Good</span></div>
                            </div>
                         </div>
                      </li>
                    )}
                      {item.fair === 1 && (
                               <li className="col-md-6" key={index}>
                               <div className="card-each-list">
                                  <i className="icon-box">
                                  <img src="../../img/tickgreen.svg" alt=""/>
                                  </i>
                                  <div className="label-text">
                                     <div className="value-text label-left">{item.attr_id} : <span className="tagspt yellow">Fair</span></div>
                                  </div>
                               </div>
                            </li>
                    )}
                    {item.poor === 1 && (
                      <li className="col-md-6" key={index}>
                                    <div className="card-each-list">
                                       <i className="icon-box">
                                       <img src="../../img/tickgreen.svg" alt=""/>
                                       </i>
                                       <div className="label-text">
                                          <div className="value-text label-left">Car Electricals: <span className="tagspt red">Poor</span></div>
                                       </div>
                                    </div>
                                 </li>
                    )}
                  </React.Fragment>
                    ))}
                  </ul>
                            
                           </div>
                           }

                     {details &&
                     details.attributes &&
                     details.attributes.length > 0 &&
                           <div className="outer-card-container">
                              <h2>Features</h2>
                              <ul className="row card-details-list">
                              {details &&
                     details.attributes &&
                     details.attributes.length > 0 &&
                     details.attributes.map((item, index) => (
                     <React.Fragment key={`${item.attr_id}-${index}`}>
                                 <li className="col-md-6">
                                    <div className="card-each-list">
                                       <div className="label-text">
                                          <div className="label">{item.attribute_id}</div>
                                          <span className="value-text ">{item.attribute_value_id}</span>
                                       </div>
                                    </div>
                                 </li>
                                 </React.Fragment>
                    ))}
                              </ul>
                           </div>
                     }
                        </div>
                     </div>
                     <div className="col-lg-5">
                        <div className="detailscontent sticky-sidebar">
                           <div className="titlehead">
                            <h2>
                            {details &&
                            details.product_details &&
                            details.product_details.name}
                            </h2>
                            {localStorage.getItem("signInResponse") != null ?
               <a href="#" onClick={() => wishlist(details.product_details)} className={`wishlistpt ${details &&
                details &&
                details.is_wishlisted  ? "active" : ""}`}>
                {!details.product_details || !details.is_wishlisted ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                    >
                        <path
                            d="M22.089 12.7938L12.5106 23L2.93227 12.7938C2.30049 12.1323 1.80284 11.3373 1.47067 10.4588C1.1385 9.5803 0.979002 8.6373 1.00221 7.6892C1.02543 6.7411 1.23085 5.80844 1.60555 4.94996C1.98025 4.09147 2.51611 3.32575 3.17938 2.70102C3.84265 2.07629 4.61897 1.60608 5.45944 1.31999C6.29992 1.03391 7.18635 0.938157 8.06292 1.03876C8.93948 1.13936 9.78719 1.43414 10.5527 1.90454C11.3181 2.37493 11.9848 3.01075 12.5106 3.77196C13.0388 3.01628 13.7062 2.38601 14.4711 1.92061C15.2361 1.45521 16.0821 1.16469 16.9562 1.06723C17.8303 0.969769 18.7137 1.06747 19.5511 1.35422C20.3885 1.64097 21.1619 2.11059 21.8228 2.7337C22.4837 3.3568 23.018 4.11998 23.3922 4.97545C23.7664 5.83093 23.9724 6.76029 23.9974 7.70538C24.0224 8.65046 23.8658 9.59093 23.5374 10.4679C23.2091 11.3449 22.716 12.1395 22.089 12.802"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : null}
                <i className="icon-05 hart_icon" />  
            </a>
            
                  :
                  <a href="#"  data-popup-id="LogIn" className="wishlistpt open-popup" >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M22.089 12.7938L12.5106 23L2.93227 12.7938C2.30049 12.1323 1.80284 11.3373 1.47067 10.4588C1.1385 9.5803 0.979002 8.6373 1.00221 7.6892C1.02543 6.7411 1.23085 5.80844 1.60555 4.94996C1.98025 4.09147 2.51611 3.32575 3.17938 2.70102C3.84265 2.07629 4.61897 1.60608 5.45944 1.31999C6.29992 1.03391 7.18635 0.938157 8.06292 1.03876C8.93948 1.13936 9.78719 1.43414 10.5527 1.90454C11.3181 2.37493 11.9848 3.01075 12.5106 3.77196C13.0388 3.01628 13.7062 2.38601 14.4711 1.92061C15.2361 1.45521 16.0821 1.16469 16.9562 1.06723C17.8303 0.969769 18.7137 1.06747 19.5511 1.35422C20.3885 1.64097 21.1619 2.11059 21.8228 2.7337C22.4837 3.3568 23.018 4.11998 23.3922 4.97545C23.7664 5.83093 23.9724 6.76029 23.9974 7.70538C24.0224 8.65046 23.8658 9.59093 23.5374 10.4679C23.2091 11.3449 22.716 12.1395 22.089 12.802"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <i className="icon-05 hart_icon" />  
                </a>
                  }
                           </div>
                           <div className="listboxouter listsinglebar">
                              <ul className="listviewsrow ">
                                 <li>
                                    <div className="cardbox">
                                       <div className="iconimg">
                                          <img src="../../img/km.svg" alt=""/>
                                       </div>
                                       <div className="cardcontent">
                                        
                                          <p> {details &&
                            details.product_details &&
                            details.product_details.kms_driven} kms</p>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="cardbox">
                                       <div className="iconimg">
                                          <img src="../../img/petrol.svg" alt=""/>
                                       </div>
                                       <div className="cardcontent">
                                          <p> {details &&
                            details.product_details &&
                            details.product_details.fuel_type}</p>
                                       </div>
                                    </div>
                                 </li>
                                 <li>
                                    <div className="cardbox">
                                       <div className="iconimg">
                                          <img src="../../img/import.svg" alt=""/>
                                       </div>
                                       <div className="cardcontent">
                                       <p> {details &&
                            details.product_details &&
                            details.product_details.tractor_ownership}</p>
                                       </div>
                                    </div>
                                 </li>
                                 {details &&
                            details.product_details &&
                            details.product_details.type_product !== null && details.product_details.type_product !== '' ?
                                 <li>
                                    <div className="cardbox">
                                       <div className="iconimg">
                                          <img src="../../img/feature.svg" alt=""/>
                                       </div>
                                       <div className="cardcontent">
                                       <p> {details &&
                            details.product_details &&
                            details.product_details.type_product}</p>
                                       </div>
                                    </div>
                                 </li>
                                 :''}
  
                                 <li>
                                    <div className="cardbox">
                                       <div className="iconimg">
                                          <img src="../../img/year.svg" alt=""/>
                                       </div>
                                       <div className="cardcontent">
                                       <p> {details &&
                            details.product_details &&
                            details.product_details.year}</p>
                                       </div>
                                    </div>
                                 </li>
                              </ul>
                           </div>
                           <div className="dtls-title-inside">
                              <h2> 
                              {details &&
                          details.product_details &&
                          details.product_details.price}{" "}
                          </h2>
                           </div>
                           <div className="swopper-outer">
                              <h2 className="smtitle">Seller</h2>
                              <div className="swopperrow">
                                 <div className="swopper-left">
                                    <div className="swopperimg">
                                 {details &&
                                 details.user &&
                                 details.user.smallimage  ? (
                                 <img
                                 onError={addDefaultSrc}
                                 src={
                                 details.user.smallimage
                                 }
                                 alt=""
                                 />
                                 ) : (
                                 <img src="../../img/chat1.png" alt="" />
                                 )}
                                    </div>
                                    <div className="swoppercontent">
                                    <h2 className="swoppertitle">
                                 {details &&
                                 details.user &&
                                 details.user.first_name + details.user.last_name}
                                 </h2>
                                 <p> {details &&
                                 details.reviews_rating &&
                                 details.reviews_rating.total_reviews }{" "} Reviews</p>
                                    </div>
                                 </div>
                                 <div className="swopper-right">
                                 <div className="datetimept">
                        Member since{" "}
                        {details &&
                          details.member_since 
                         }
                      </div>
                                    <a href="#" 
                            onClick={(e) =>
                              viewProfile(
                                e,
                                details &&
                            details.user &&
                           details.user.slug
                              )
                            } className="swoppername">
                        View Profile <i className="icon-01"></i>
                      </a>
                                 </div>
                              </div>
                              <div className="btn-row btn-bar-top-border btn-row-new">
                              {/* <a href="#" className=""><i>  <img src="../../img/year.svg" alt=""/></i> Chat</a>  */}

                              <a href={whatsappLink} target="_blank" className="btn btn-whatsapp">
                              <img src="../../img/whatsappfixed-btn.svg" alt="whatsapp"/> Chat
                              </a>

                              {localStorage.getItem("signInResponse") != null ?  <a href="javascript:void(0);" className="btn btn-border" onClick={() => sellerinquiry('login')}  >Request Callback</a>  
                               : <a href="javascript:void(0);" className="btn btn-border open-popup"  data-popup-id="RequestCallback">Request Callback</a> }
                              <a href="#" className="btn btn-primary">Start Chat</a>  
                             
                              </div>
                           </div>
                     
                           <div className="repott-share-foot">
                     {localStorage.getItem("signInResponse") != null ? 
                     reportitem.is_reported == 1 ?
                     <a href="#" onClick={openReportModal} >
                     <i>
                     <img src="../img/heart.png" alt="" />
                     </i> Report this Listing
                     </a>
                     :
                     <a href="#" data-popup-id="chooseanyonereport" onClick={openReportModal} className="open-popup">
                     <i>
                     <img src="../img/heart.png" alt="" />
                     </i> Report this Listing
                     </a>
                     :
                     <a href="#" data-popup-id="LogIn" className="open-popup">
                     <i>
                     <img src="../img/heart.png" alt="" />
                     </i> Report this Listing
                     </a>
                     }

                  <a href="javascript:void(0);" onClick={openShareModal}>
                    <i>
                      <img src="../img/shareclip.png" alt="" />
                    </i>{" "}
                    Share this Listing
                  </a>
                          </div>

                         
                        </div>
                     </div>
                  </div>
               </section>
               <div className={`center-modal-default forgotmodel sharemodel ${
            isOpen ? "open-it" : "d-none"
          }`} >
          <div className="shadowpt" ></div>
          <div className="bottom-modal-content">
           
            <div className="modal-swop-header">
              <div className="modal-swop-title">Share this Automobile</div>
              {/* <div className="close-modal" >
              <span className="icon-10">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </span>
            </div> */}
            <a href="javascript:void(0);" className="close_panel" onClick={closeShareModal}><i><img src="../../img/close.png" alt=""/></i></a>
            </div>


            <div className="default-modal-body">
              <FacebookShareButton
                url={shareitem.share_url}
                quote={shareitem.share_title}
                hashtag={shareitem.share_hashtag}
                className={""}
              >
                <FacebookIcon size={50} />
              </FacebookShareButton>

              <WhatsappShareButton
                url={shareitem.share_url}
                title={shareitem.share_title}
                className={""}
              >
                <WhatsappIcon size={50} />
              </WhatsappShareButton>

              <TwitterShareButton
                url={shareitem.share_url}
                title={shareitem.share_title}
                hashtags={shareitem.share_hashtag}
                className={""}
              >
                <TwitterIcon size={50} />
              </TwitterShareButton>

              <LinkedinShareButton
                url={shareitem.share_url}
                title={shareitem.share_title}
                summary={shareitem.share_hashtag}
                className={""}
              >
                <LinkedinIcon size={50} />
              </LinkedinShareButton>
            </div>
          </div>
                          </div>


{/*------------ Image popup--------------- */}

<div className={`center-modal-default forgotmodel sharemodel ${
            isModalOpen ? "open-it" : "d-none"
          }`} >
          <div className="shadowpt" onClick={closeModal}></div>
          <div className="bottom-modal-content" style={{maxWidth: '520px'}}>
           
            <div className="modal-swop-header previewehead">
              <a href="javascript:void(0);" class="close_panel" onClick={closeModal}><i><img src="../../img/close.png" alt=""/></i></a>
            </div>

            <div className="default-modal-body">
            <img src={currentImage} alt="" style={{ width: '100%', height: 'auto' }} />
            </div>
          </div>
</div>


                               {/*------------ Report popup--------------- */}
<div className="comman-model comman-side-popup choose-any-model" id="chooseanyonereport">
       <div className="comman-popup-inner">
       <div className="top-model-header">
          <div className="pop-top-title">
             <h2>Report</h2>
          </div>
          <a href="javascript:void(0);" className="close_panel"><i><img src="../../img/close.png" alt=""/></i></a>
       </div>
      
       <div className="popup-model-body"> 
       <div className="form-group">
        <label>Select Reason</label>
              <select
                  value={selected_report_reason}
                  onChange={(event) =>
                    setSelectedreportreason( event.target.value,
                    )
                  }
                  className="input-field form-control"
                >
                  <option value="">-- Select a Reason --</option>
                  {report_reasons.map((item, index) => {
                    return <option key={index}>{item}</option>;
                  })}
                </select> 
          </div>
          
          
          {selected_report_reason == "Other" ? ( 
                <div className="input-box form-group">
                   <label>Write Reason</label>
                  <input
                    type="text"
                    value={selected_report_reason_other}
                    onChange={(event) =>
                      setSelectedreportreasonother(event.target.value,
                      )
                    }
                    className="input-field form-control"
                  />
                </div>
              ) : (
                ""
              )}
          
           
       </div>
       <div className="model-footer-panel"> <button className="btn btn-primary" onClick={reportSwop}>Report</button></div>
    </div>
        <div className="shadowpt" ></div>
      </div>

      <div className="comman-model comman-side-popup request-callback-model" id="RequestCallback">
        
            <div className="comman-popup-inner">
               <div className="top-model-header">
                  <div className="pop-top-title">
                     <h2>Request Callback</h2>
                  </div>
                  <a href="javascript:void(0);"  onClick={closeRequestpopup} className="close_panel"><i><img src="../../img/close.png" alt=""/></i></a>
               </div>
              
               <div className="popup-model-body">
                  <div className="form-group">
                     <label>Your Name</label> 
                     <input type="text"  name="name" id="nameInput" className="form-control" required placeholder="Enter Your Name"/>  
                  </div>
                  <div className="form-group">
                     <label>Mobile Number</label> 
                     <input type="text" name="mobile_number" value={mobileNumbers} maxLength={15} id="mobileInput" onChange={handleInputChange} className="form-control" placeholder="Enter Mobile Number"/>  
                  </div>
               </div>
              
               <div className="model-footer-panel justify-content-end">      
                  <button className="btn btn-primary close-click" onClick={sellerinquiry}>Send Inquiry</button>
               </div>
               
            </div>
         
         <div className="shadowpt clearfix"></div>
      </div>

{/*------------ Report popup--------------- */}
               <section className="sa-comman-section">
                  <div className="sa-heading-section">
                     <h2 className="sa-title">Similar  <span>Automobile's</span></h2>
                  
                     <div className="clear"></div>
                  </div>
                  <div className="sa-fpc-wrap">
                     <div id="swopcafetown" className="default-carousel-simple">
                     {details &&
                details.similar_items &&
                details.similar_items.map((card, index) => (
                  <Cards
                  key={index}
                  image={card.image}
                  title={card.name}
                  price={card.price}
                  cardtype={'automobile'}
                  kmcontent={card.city}
                  kms_driven={card.kms_driven}
                  fuel_type={card.fuel_type}
                  transmission_type={card.year}
                  slug={card.slug}
                  type={card.type}
                  type_product={card.type_product}
                />
                ))}
                     </div>
                  </div>
               </section>
            </div>
      {/* <SignIn />
      <SignUp /> */}
      
      {/* <a href={whatsappLink} target="_blank" className="whats-app-fixed-btn zc-whats-btn-click">
        <img src="../../img/whatsappfixed-btn.svg" alt="whatsapp"/>
      </a> */}

    </main>
  );
};

export default VehicleDetail;
