import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { SocketProvider } from "socket.io-react";
import { getSignInResponse } from "./utils/authUtil";
import io from "socket.io-client";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const socket = io("https://swopanything.girnarsoft.co.in");
// const socket = io("https://sellanything247.com");
let userData = getSignInResponse();

// socket.on("connect", function () {
//   console.log("socket connected");
//   console.log(socket.id);

//   let data = { userId: userData.id };
//   socket.emit("join", data);

//   socket.on("get-message", function (dataObj) {
//     if (typeof displayMessage === "function") {
//       //playNotificationSound();
//       //displayMessage(data, 0);
//     }
//   });

//   socket.on("disconnect", function () {
//     socket.emit("leave", data);
//     console.log("socket disconnected");
//   });
// });

// socket.on("connect_error", function (err) {
//   console.log("socket connect_error");
//   console.log(err);
// });

root.render(
  <Provider store={store}>
    {/* <SocketProvider socket={socket}> */}
      <App />
    {/* </SocketProvider> */}
  </Provider>
);

reportWebVitals();
